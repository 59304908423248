<template>
  <div>
    <FunnelBreadcrumb v-if="!hasMinimal" />

    <div class="mx-auto my-0 max-w-[34rem] p-24">
      <p class="body-1 mb-32 h-56 border-b py-16 text-center">
        {{ i18n(translations.headerTitle) }}
      </p>
      <h1 class="heading-1 mb-16">
        {{ i18n(translations.punchline) }}
      </h1>
      <p class="body-1 mb-16" data-qa="offer" data-test="offer">
        <FormattedMessage :definition="translations.offer">
          <template #amount>
            <span class="body-1-bold">
              {{ offerPrice }}
            </span>
          </template>
          <template #model>
            <span class="body-1-bold">
              {{ data?.title }}
            </span>
          </template>
        </FormattedMessage>
      </p>
      <div
        class="mx-auto my-0 hidden max-w-[34rem] text-right md:mx-0 md:mb-24 md:mt-16 md:block"
      >
        <RevButton data-qa="decline" variant="secondary" @click="declineOffer">
          {{ i18n(translations.decline) }}
        </RevButton>
        <RevButton
          class="ml-16"
          data-qa="accept"
          variant="primary"
          @click="acceptOffer"
        >
          {{ i18n(translations.accept) }}
        </RevButton>
      </div>
      <div
        class="border-static-default-mid rounded-md mb-24 overflow-hidden border px-20"
      >
        <RevList>
          <RevListItemStatic
            v-for="(diagnostic, index) in data?.diagnostic_summary"
            :key="`${index}-${diagnostic.value}`"
          >
            <template #label>
              <p class="body-1">
                {{ diagnostic.label }}
              </p>
            </template>
            <template #suffix>
              <p class="body-1">
                {{ diagnostic.value }}
              </p>
            </template>
          </RevListItemStatic>
        </RevList>
      </div>
      <p class="body-1-bold mb-16">
        {{ i18n(translations.nextStep) }}
      </p>
      <div
        v-for="wording in data?.wording"
        :key="wording.title"
        class="mb-16 flex"
      >
        <div>
          <IconCheckInCircle />
        </div>
        <div class="ml-16">
          <p class="body-1">
            {{ wording.title }}
          </p>
          <p
            v-for="paragraph in wording.paragraphs"
            :key="paragraph"
            class="body-2"
          >
            {{ paragraph }}
          </p>
        </div>
      </div>
      <span class="my-32 block h-1 w-full" />
      <p class="body-1-bold mb-16">
        {{ i18n(translations.lastThing) }}
      </p>
      <p class="body-1">
        {{ i18n(translations.lastThingParagraph) }}
      </p>
      <div
        class="mx-auto my-0 hidden max-w-[34rem] text-right md:mx-0 md:mb-24 md:mt-16 md:block"
      >
        <RevButton data-qa="decline" variant="secondary" @click="declineOffer">
          {{ i18n(translations.decline) }}
        </RevButton>
        <RevButton
          class="ml-16"
          data-qa="accept"
          variant="primary"
          @click="acceptOffer"
        >
          {{ i18n(translations.accept) }}
        </RevButton>
      </div>
    </div>
    <RevStickyBar class="md:hidden">
      <div
        class="mx-auto my-0 flex max-w-[34rem] text-right md:mx-0 md:mb-24 md:mt-16"
      >
        <RevButton
          data-qa="decline"
          full-width="always"
          variant="secondary"
          @click="declineOffer"
        >
          {{ i18n(translations.decline) }}
        </RevButton>
        <RevButton
          class="ml-16"
          data-qa="accept"
          full-width="always"
          variant="primary"
          @click="acceptOffer"
        >
          {{ i18n(translations.accept) }}
        </RevButton>
      </div>
    </RevStickyBar>
  </div>
</template>

<script lang="ts" setup>
import { createError, useRoute, useRouter } from '#imports'
import { computed, inject, onMounted, ref, watch } from 'vue'

import { type GetOfferResponseLegacy } from '@backmarket/http-api/src/api-specs-buyback/customer/getOfferLegacy'
import { getOfferV1 } from '@backmarket/http-api/src/api-specs-buyback/customer/getOfferV1'
import type { HttpError } from '@backmarket/http-api/src/utils/HttpApiError'
import { buybackOfferToLegacyOffer } from '@backmarket/nuxt-layer-buyback/adapters/buybackOffer/buybackOffer'
import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'
import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { omit } from '@backmarket/utils/object/omit'
import { RevButton } from '@ds/components/Button'
import { RevList } from '@ds/components/List'
import { RevListItemStatic } from '@ds/components/ListItemStatic'
import { RevStickyBar } from '@ds/components/StickyBar'
import { IconCheckInCircle } from '@ds/icons/IconCheckInCircle'
import { storeToRefs } from 'pinia'

import { FUNNEL_ERROR_MESSAGE } from '~/scopes/buyback/pages/constants'
import { useBuyBackStore } from '~/scopes/buyback/store'
import { CMS } from '~/scopes/cms/routes-names'

import FunnelBreadcrumb from '../components/FunnelBreadcrumb/FunnelBreadcrumb.vue'
import type { ErrorData } from '../components/TheCatcher/useCatcher'
import { ROUTE_NAMES } from '../constants'

import translations from './Offer.translations'

const i18n = useI18n()
const route = useRoute()
const router = useRouter()
const logger = useLogger()
const { trackClick } = useTracking()
const buybackStore = useBuyBackStore()
const experiments = useExperiments()

const { offer, hasOffer } = storeToRefs(buybackStore)
const hasMinimal = experiments['experiment.buybackHeader'] === 'withMinimal'
const getOfferPayload = omit(route.query, ['nextStep', 'listingPublicId'])
const errorData = inject<ErrorData>('errorData', ref({ message: '', step: '' }))
errorData.value = {
  message: FUNNEL_ERROR_MESSAGE.BUYBACK_FUNNEL,
  step: 'Offer',
}

const {
  data: offerV1Payload,
  error: offerV1Error,
  status: offerV1Status,
} = await useHttpFetch(getOfferV1, {
  queryParams: {
    ...route.query,
    category: route.params?.id,
  },
  pathParams: {
    kind: 'regular',
  },
  immediate: !hasOffer.value,
  transform: (unformattedData) =>
    unformattedData
      ? buybackOfferToLegacyOffer(
          unformattedData,
          route.query,
          i18n.currencySign,
        )
      : null,
})

const data = computed<null | GetOfferResponseLegacy>(() => {
  if (hasOffer.value) return offer.value

  return offerV1Payload.value
})

const offerPrice = computed(() => {
  return data?.value?.standard_price
    ? i18n.price(data?.value?.standard_price)
    : ''
})

onMounted(() => {
  if (!route.query)
    router.push({
      name: CMS.BUYBACK,
      params: {
        pageName: 'home',
      },
    })
})

watch(
  offerV1Status,
  (newStatus) => {
    if (!data.value && newStatus === 'success') {
      // OFFER EXPIRED
      router.push({
        name: CMS.BUYBACK,
        params: {
          pageName: 'home',
        },
      })
    }
  },
  { immediate: true },
)

if (offerV1Error.value) {
  const error = offerV1Error.value as HttpError
  logger.error(FUNNEL_ERROR_MESSAGE.GET_OFFER, { error })
  // fatal: true option redirects to Error view
  throw createError({
    statusCode: offerV1Error.value.statusCode,
    statusMessage: offerV1Error.value.message,
    fatal: true,
  })
}

const nextStep = computed(() => {
  const query = route?.query?.partner
    ? {
        partner: route.query.partner,
        listingPublicId: route.query.listingPublicId,
      }
    : { listingPublicId: route.query.listingPublicId }

  return data.value?.hasBankDetails
    ? {
        name: ROUTE_NAMES.BANK,
        params: route.params,
        query,
      }
    : {
        name: ROUTE_NAMES.ADDRESS,
        params: route.params,
        query,
      }
})

const trackAction = (name: string) => {
  trackClick({
    zone: 'buyback',
    name,
    value: {
      ...getOfferPayload,
      estimation: data.value?.price.toString(),
    },
  })
}

function acceptOffer() {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  buybackStore.setBuyBackOffer(data.value!)
  trackAction('accept_offer')
  router.push(nextStep.value)
}

function declineOffer() {
  trackAction('decline_offer')
  router.push({
    name: CMS.BUYBACK,
    params: {
      pageName: 'home',
    },
  })
}
</script>
